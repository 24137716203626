import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router, RouterModule, RouterOutlet } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebsocketModule } from '@app/libs/websocket';
import { FingerprintJSPro, FingerprintjsProAngularModule } from '@fingerprintjs/fingerprintjs-pro-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsPageComponent } from './app/icons-page/icons-page.component';
import { SegmentEventsPageComponent } from './app/segment-events-page/segment-events-page.component';
import { DemoEnvironmentComponent } from './app/shared/components/demo-environment/demo-environment.component';
import { UiPageComponent } from './app/ui-page/ui-page.component';
import { CoreModule } from './core/core.module';
import { TitleService } from './shared/services/title/title.service';
import { SharedModule } from './shared/shared.module';
import { CustomRouteReuseStrategy } from './shared/utils';

// After upgrading to Angular material 13, the tooltips on the iOS app (only)
// were seemingly eating the first tap event, not allowing links/modals/dropdowns to open.
// After we upgrade to angular 14, we should test if this is still necessary.
export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  touchGestures: 'off',
};

declare global {
  interface Window {
    analytics: any;
    growsurf?: {
      getReferrerId: () => string;
    };
    mountainConfig?: {
      orderId: string;
    };
  }
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    RouterOutlet,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'My Hospitable',
      maxAge: 25,
      logOnly: environment.production,
    }),
    WebsocketModule.forRoot(environment.websocket),
    FingerprintjsProAngularModule.forRoot({
      loadOptions: {
        apiKey: environment.fingerprintPro.publicApiKey,
        endpoint: [environment.fingerprintPro.url, FingerprintJSPro.defaultEndpoint],
        scriptUrlPattern: [
          environment.fingerprintPro.url + '/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      },
    }),
    HttpClientModule,
  ],

  declarations: [
    AppComponent,
    DemoEnvironmentComponent,
    IconsPageComponent,
    SegmentEventsPageComponent,
    UiPageComponent,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults },
    {
      provide: 'apiUrl',
      useFactory: () => environment.apiUrl,
    },
    {
      provide: 'apiMetricsUrl',
      useFactory: () => environment.apiMetricsUrl,
    },
    {
      provide: 'optimizelySdkKey',
      useFactory: () => environment.optimizely.sdkKey,
    },
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private titleService: TitleService) {
    this.titleService.init();
  }
}
