<div class="flex flex-col sbnbDesktop:flex-row" data-element="app-main-wrapper" id="app-container">
  <sbnb-nav *ngIf="authService.isLoggedInSubject | async"></sbnb-nav>
  <div class="flex-1" data-element="app-content-area" id="main-app-area">
    <sbnb-helptext-wrapper *ngIf="!isDemo" [area]="'global'"></sbnb-helptext-wrapper>
    <sbnb-onboarding-banner *ngIf="!isDemo"></sbnb-onboarding-banner>
    <sbnb-demo-environment *ngIf="isDemo"></sbnb-demo-environment>
    <sbnb-toast-container></sbnb-toast-container>

    <sbnb-command-k></sbnb-command-k>
    <router-outlet></router-outlet>
  </div>
  <sbnb-menu-mobile-tabs
    *ngIf="
      featureFlagMobileNavTabsEnabled &&
      (authService.user$ | async) &&
      (deviceDetectionService.$isMobile | async) === true &&
      !shouldHideMenuBasedOnUrl
    "></sbnb-menu-mobile-tabs>
</div>
