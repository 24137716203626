import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'sbnb-demo-environment',
  templateUrl: './demo-environment.component.html',
  styleUrls: ['./demo-environment.component.scss'],
})
export class DemoEnvironmentComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
