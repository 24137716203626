import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PosthogFeatureFlag } from '@app/shared/interfaces/lib/feature-flags.interface';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { Logger } from '@app/shared/utils';
import { Observable, timer } from 'rxjs';
import { expand, map, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private readonly INITIAL_INTERVAL_MS = 5_000; // 5 seconds
  private readonly MAX_INTERVAL_MS = 60_000; // 60 seconds
  private readonly BACKOFF_FACTOR = 1.5; // Each interval will be 1.5x longer than the previous

  constructor(
    private posthogService: PosthogService,
    private router: Router
  ) {}

  init() {
    // Create an observable that emits the current interval
    const checkInterval$: Observable<number> = timer(0).pipe(
      map(() => this.INITIAL_INTERVAL_MS),
      expand((currentInterval) => {
        // Check maintenance mode status
        this.checkMaintenanceMode();

        // Calculate next interval with exponential backoff
        const nextInterval = Math.min(currentInterval * this.BACKOFF_FACTOR, this.MAX_INTERVAL_MS);

        // Wait for the calculated interval before emitting next value
        return timer(currentInterval).pipe(map(() => nextInterval));
      }),
      takeWhile((interval) => interval <= this.MAX_INTERVAL_MS, true)
    );

    // Subscribe to start the interval checks
    checkInterval$.subscribe((interval) => {
      Logger.log(`Next maintenance check in ${interval / 1000} seconds`);
    });
  }

  private checkMaintenanceMode() {
    this.posthogService
      .getRemoteConfigPayload$<{
        maintenance_mode_active: boolean;
        message: string;
        estimated_utc_resolution: string;
      }>(PosthogFeatureFlag.AppMaintenanceMode)
      .subscribe((payload) => {
        if (!payload) return;
        if (payload.maintenance_mode_active === undefined) return;

        const { maintenance_mode_active } = payload;

        if (maintenance_mode_active === true && this.router.url !== '/maintenance') {
          Logger.log('Maintenance mode is active, redirecting to maintenance page');
          window.location.href = '/maintenance';
        }

        if (maintenance_mode_active === false && this.router.url === '/maintenance') {
          Logger.log('Maintenance mode is not active, redirecting to home page');
          window.location.href = '/';
        }
      });
  }
}
